a:bottom-menu {
    color: white;
    text-decoration: none;
  }

a.bottom-menu:link {
    color: #ffffff;
    text-decoration: none;
  }
  
a.bottom-menu:visited {
    color: lightgray;
    text-decoration: none;
  }
  
a.bottom-menu:hover {
    color: gray;
    text-decoration: none;
  }

#bottom-menu {
    padding-right: 40px;
  }