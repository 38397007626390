body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}

form label{
  font-weight: bold;
}

.navbar-brand {
  color: var(--bs-navbar-brand-hover-color);
  font-size: 32px;
}

.bg-secondary {
  background-color: #801717 !important;
}

.breadcrumb {
  --bs-breadcrumb-margin-bottom: 0;
}

.bg-light {
  background-color: #eee !important;
}

.navbar {
  padding-top: 12px;
  padding-bottom: 12px;
}

.alert {
  --bs-alert-margin-bottom: 0;
}

