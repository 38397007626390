a:top-menu {
  color: rgb(207, 205, 205);
  padding-right: 40px;
  text-decoration: none;
}

a.top-menu:visited {
  color: #d1d0d1;
  text-decoration: none;
}

a.top-menu:link {
  color: #ffffff;
  text-decoration: none;
}

 a.top-menu:hover {
  color: #ffffff;
  text-decoration: underline;
  padding: 0 0 0 0;
}

a.top-menu:active {
  color: #000000;
  text-decoration: none;
}

a.navbar-nav:link {
  color: #ffffff !important;
}